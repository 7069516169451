import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import CompanySettings from "src/components/company/settings/CompanySettings";
import ReviewsComponent from "src/components/reviews/ReviewsComponent";
import NotFoundPage from "src/shared/errorpages/NotFoundPage";
import QuickbooksOnlineCallback from "src/components/settings/direct_connect/workflows/QuickbooksOnlineCallback";
import XeroCallback from "src/components/settings/direct_connect/workflows/XeroCallback";
import ServiceFusionCallback from "src/components/settings/direct_connect/workflows/ServiceFusionCallback";
import ServiceM8Callback from "src/components/settings/direct_connect/workflows/ServiceM8Callback";
import FreshbooksOnlineCallback from "src/components/settings/direct_connect/workflows/FreshbooksOnlineCallback";
import ShopifyCallback from "src/components/settings/direct_connect/workflows/ShopifyCallback";
import ZohoInvoicingCallback from "src/components/settings/direct_connect/workflows/ZohoInvoicingCallback";
import ZohoBooksCallback from "src/components/settings/direct_connect/workflows/ZohoBooksCallback";
import JobberCallback from "src/components/settings/direct_connect/workflows/JobberCallback";
import {
  MANAGE_REVIEW_TABS,
  COMPANY_SETTINGS_SIDEBAR,
  PROFILE_SETTINGS_TAB,
  COMPANY_SETTINGS_ROUTES,
  APPS_INTEGRATION_SETTINGS_TAB,
} from "src/shared/Constants";
import CustomerSegmentsContainer from "src/components/customers_segments/CustomerSegmentsContainer";
import Customer from "src/components/customers_segments/customers/customer/Customer";
import SegmentsSummaryContainer from "src/components/customers_segments/segments/SegmentsSummaryContainer";
import Segment from "src/components/customers_segments/segments/segment/Segment";
import AcceptTerms from "src/shared/accept-terms/AcceptTerms";
import { useSelector } from "react-redux";

// lazy loaded
const PostCardCampaigns = React.lazy(() =>
  import("src/components/campaigns/postcard/PostCardCampaigns"),
);
const ProspectCampaigns = React.lazy(() =>
  import(
    "src/components/prospect_marketing/prospect_campaign/ProspectCampaigns"
  ),
);
const EmailCampaigns = React.lazy(() =>
  import("src/components/campaigns/email/EmailCampaigns"),
);
const TextCampaigns = React.lazy(() =>
  import("src/components/campaigns/text/TextCampaigns"),
);
const Appointments = React.lazy(() =>
  import("src/components/appointments/Appointments"),
);
const HelpCenterScreen = React.lazy(() =>
  import("src/containers/help_center/HelpCenterScreen"),
);
const MyProfileScreen = React.lazy(() =>
  import("src/containers/profile/MyProfileScreen"),
);
const Messaging = React.lazy(() =>
  import("src/components/messaging/Messaging"),
);
const Campaigns = React.lazy(() =>
  import("src/components/campaigns/Campaigns"),
);
const ServiceFusionGettingStarted = React.lazy(() =>
  import("src/components/service_fusion/GettingStarted"),
);
const Dashboard = React.lazy(() =>
  import("src/components/dashboard/Dashboard"),
);
const ProspectMarketing = React.lazy(() =>
  import("src/components/prospect_marketing/ProspectMarketing"),
);
const ProspectJourney = React.lazy(() =>
  import("src/components/prospect_marketing/journey/ProspectJourney"),
);
const BudgetCalculator = React.lazy(() =>
  import("src/components/prospect_marketing/calculator/BudgetCalculator"),
);

const ContainerRoutes = ({ client }) => {
  /**
   * This function is used to authenticate the route for terms accepted at the moment.
   * We can update it in future for other authentications as well.
   * @param {JSX} component
   * @returns JSX component
   */
  const { userInfo, acceptsTerms } = useSelector((state) => state.user);

  const authenticateRoute = (component) => {
    return acceptsTerms ? (
      <>{component}</>
    ) : (
      <Redirect to={`/companies/${userInfo.companyId}/terms`} />
    );
  };

  return (
    // <BrowserRouter>
    <Switch>
      {/* Routes for review component tabs */}
      {Object.keys(MANAGE_REVIEW_TABS)
        .map((key) => MANAGE_REVIEW_TABS[key])
        .map((tab, index) => (
          <Route
            key={index}
            path={`/companies/:id/${tab.path}`}
            render={() =>
              authenticateRoute(
                <ReviewsComponent tab={tab.path} client={client} />,
              )
            }
          />
        ))}

      {/* Routes for oauth callbacks */}
      <Route
        path="/companies/callbacks/quickbooks-online"
        render={() => authenticateRoute(<QuickbooksOnlineCallback />)}
      />
      <Route
        path="/companies/callbacks/xero"
        render={() => authenticateRoute(<XeroCallback />)}
      />
      <Route
        path="/companies/callbacks/service-fusion"
        render={() => authenticateRoute(<ServiceFusionCallback />)}
      />
      <Route
        path="/companies/callbacks/zoho-books"
        render={() => authenticateRoute(<ZohoBooksCallback />)}
      />
      <Route
        path="/companies/callbacks/servicem8"
        render={() => authenticateRoute(<ServiceM8Callback />)}
      />
      <Route
        path="/companies/callbacks/freshbooks-online"
        render={() => authenticateRoute(<FreshbooksOnlineCallback />)}
      />
      <Route
        path="/companies/callbacks/shopify"
        render={() => authenticateRoute(<ShopifyCallback />)}
      />
      <Route
        path="/companies/callbacks/zoho-invoicing"
        render={() => authenticateRoute(<ZohoInvoicingCallback />)}
      />
      <Route
        path="/companies/callbacks/jobber"
        render={() => authenticateRoute(<JobberCallback />)}
      />
      {/* feature routes */}
      <Route
        path="/companies/:id/service-fusion-getting-started"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <ServiceFusionGettingStarted client={client} />
            </React.Suspense>,
          )
        }
      />
      <Route
        path={`/companies/:id/${COMPANY_SETTINGS_ROUTES.messaging}`}
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <Messaging />
            </React.Suspense>,
          )
        }
      />
      <Route
        path={`/companies/:id/${COMPANY_SETTINGS_ROUTES.campaigns}`}
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <Campaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/settings"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.profileSetting}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/review-settings"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.review}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/widgets"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.widgets}
              client={client}
            />,
          )
        }
      />
      <Route
        path={`/companies/:id/${COMPANY_SETTINGS_ROUTES.texting}`}
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.texting}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/appointment-settings"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.appointments}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/syndication-settings"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.syndication}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/campaigns-settings"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.campaign}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/direct-connect-settings"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.directConnect}
              client={client}
            />,
          )
        }
      />
      <Route
        exact
        path="/companies/:companyId/users/:userId/my_profile"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <MyProfileScreen />
            </React.Suspense>,
          )
        }
      />
      <Route
        path="/companies/:companyId/help-center"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <HelpCenterScreen />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/users"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.users}
              client={client}
            />,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/users/add"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.users}
              client={client}
            />,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/users/:userId/edit"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.users}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/billing"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={false}
              tab={COMPANY_SETTINGS_SIDEBAR.billing}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/apps"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={APPS_INTEGRATION_SETTINGS_TAB.apps}
              tab={COMPANY_SETTINGS_SIDEBAR.app}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/integrations"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={APPS_INTEGRATION_SETTINGS_TAB.integrations}
              tab={COMPANY_SETTINGS_SIDEBAR.app}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/contact-details"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={PROFILE_SETTINGS_TAB.contactDetails}
              tab={COMPANY_SETTINGS_SIDEBAR.profileSetting}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/business-hours"
        render={() =>
          authenticateRoute(
            <CompanySettings
              tabNavigator={PROFILE_SETTINGS_TAB.businessHours}
              tab={COMPANY_SETTINGS_SIDEBAR.profileSetting}
              client={client}
            />,
          )
        }
      />
      <Route
        path="/companies/:id/appointments"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <Appointments />
            </React.Suspense>,
          )
        }
      />
      {/* Email Campaigns Routes */}
      <Route
        path="/companies/:id/dashboard"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <Dashboard />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/email-campaigns/new/:filterId"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <EmailCampaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/email-campaigns/new"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <EmailCampaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        path="/companies/:id/email-campaigns/:campaignId"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <EmailCampaigns />
            </React.Suspense>,
          )
        }
      />
      {/* Postcard Campaigns Routes */}
      <Route
        exact
        path="/companies/:id/postcard-campaigns/new"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <PostCardCampaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/postcard-campaigns/:campaignId"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <PostCardCampaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/prospect-campaigns/:campaignId"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <ProspectCampaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/postcard-campaigns/new/:filterId"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <PostCardCampaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/postcard-campaigns/new?filter=:filterName"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <PostCardCampaigns />
            </React.Suspense>,
          )
        }
      />
      {/* Text Campaigns Routes */}
      <Route
        exact
        path="/companies/:id/text-campaigns/new"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <TextCampaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/text-campaigns/:campaignId"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <TextCampaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/text-campaigns/new/:filterId"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <TextCampaigns />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/segments/new"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <Segment />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/segments/:filterId/edit"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <Segment />
            </React.Suspense>,
          )
        }
      />
      <Route
        path="/companies/:id/customers/:customerId"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <Customer />
            </React.Suspense>,
          )
        }
      />
      <Route
        path="/companies/:id/customers"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <CustomerSegmentsContainer tab="0" />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/segments"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <CustomerSegmentsContainer tab="1" />
            </React.Suspense>,
          )
        }
      />
      <Route
        exact
        path="/companies/:id/segments/:filterId/details"
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <SegmentsSummaryContainer />
            </React.Suspense>,
          )
        }
      />
      <Route
        path={`/companies/:id/prospect-marketing`}
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <ProspectMarketing />
            </React.Suspense>,
          )
        }
      />
      <Route
        path={`/companies/:id/prospect-journey`}
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <ProspectJourney />
            </React.Suspense>,
          )
        }
      />
      <Route
        path={`/companies/:id/edit-prospect-journey/:campaignId`}
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <ProspectJourney />
            </React.Suspense>,
          )
        }
      />
      <Route
        path={`/companies/:id/prospect-calculator/:campaignId`}
        render={() =>
          authenticateRoute(
            <React.Suspense fallback={<React.Fragment />}>
              <BudgetCalculator />
            </React.Suspense>,
          )
        }
      />
      <Route exact path="/companies/:id/terms" render={() => <AcceptTerms />} />

      <Route render={() => <NotFoundPage />} />
    </Switch>
    // </BrowserRouter>
  );
};

export default ContainerRoutes;
