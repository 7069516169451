import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import customerLobbyIcon from "src/assets/images/common/cl-logo-header.png";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "react-apollo";
import { GET_USER_DETAILS } from "src/graphql/auth/queries";
import { useHistory } from "react-router-dom";
import { COOKIES } from "src/shared/Config";
import { loadCookie, removeCookies } from "src/shared/SessionHelper";
import { setUserDetails } from "src/redux/userSlice";
import { useParams } from "react-router-dom";
import StringUtil from "src/utils/StringUtil";
import { constants } from "src/shared/Constants";
import { ToastContainer } from "react-toastify";
import "./Auth.scss";

const AuthLayout = ({ title, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { type } = useParams();
  const [fetchUserInfo] = useLazyQuery(GET_USER_DETAILS, {
    onCompleted: async (data) => {
      if (data?.getAuthentication?.authenticated) {
        const { getAuthentication } = data;

        await dispatch(setUserDetails(getAuthentication));

        if (getAuthentication?.expired) {
          history.push(`/${type}/update-expired-password`);
        } else if (history.location.pathname.includes("enterprise")) {
          history.push(`/enterprise/dashboard`);
        } else {
          history.push(`/companies/${getAuthentication.companyId}/dashboard`);
        }
      }
    },
    onError: () => {
      removeCookies();
    },
  });

  useEffect(() => {
    const autToken = loadCookie(COOKIES.AUTH_TOKEN);
    if (autToken) {
      fetchUserInfo({
        variables: {
          token: autToken,
        },
      });
    }
    document.body.classList.add("auth-body");
    return () => {
      document.body.classList.remove("auth-body");
    };
  }, []);
  const isExpired = history.location.pathname.includes(
    "update-expired-password",
  );
  return (
    <div className="auth-container">
      <Container className="auth-wrapper">
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            <div className="auth-card">
              <div className="text-center mb-5 mt-3">
                <img src={customerLobbyIcon} className="logo mb-4" />
                <h1 className="mt-4">{title}</h1>
                <span>
                  {isExpired ? (
                    <p>
                      Your password has expired. You must update your password{" "}
                      <br /> before you can log in
                    </p>
                  ) : (
                    `${StringUtil.capitalize(type)} Account`
                  )}
                </span>
              </div>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer autoClose={constants.TOASTER_TIMER} limit={4} />
    </div>
  );
};

export default AuthLayout;
