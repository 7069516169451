import React, { useEffect, useState, useRef } from "react";
import "src/components/company_users/CompanyUsersIndex.scss";
import UpArrow from "src/assets/images/common/up_arrow.png";
import DownArrow from "src/assets/images/common/down_arrow.png";
import ActivationOptions from "src/components/company_users/ActivationOptions";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { COMPANY_USERS } from "src/graphql/users/queries";
import { UPDATE_COMPANY_USERS_ACTIVATION } from "src/graphql/users/mutations";
import {
  cleanName,
  cleanEmail,
  cleanInitials,
} from "src/utils/company_users/dataCleaner";
import classnames from "classnames";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";

const PAGE_SIZE = 10;

const CompanyUsersIndex = ({
  successMessage,
  setSuccessMessage,
  display,
  setQueriedUser,
  setDisplay,
  openModal,
}) => {
  const companyId = useGetCompanyIdFromUrl();
  const currMutationVars = useRef({ companyUserId: null, isActive: null });
  const [updateCompanyUser] = useMutation(UPDATE_COMPANY_USERS_ACTIVATION);
  const [queryOptions, setQueryOptions] = useState({
    sortField: "",
    sortDirection: "",
    page: 1,
    showTotal: true,
  });
  const [pageCount, setPageCount] = useState(0);
  const [columns, setColumns] = useState({
    0: {
      id: 0,
      header: "NAME",
      isName: true,
      columnData: [],
      sortField: "name",
    },
    1: {
      id: 1,
      header: "EMAIL",
      columnData: [],
      sortField: "email",
    },
    2: {
      id: 2,
      header: "ROLE",
      isRole: true,
      columnData: [],
      sortField: "is_manager",
    },
    3: {
      id: 3,
      header: "STATUS",
      isStatus: true,
      columnData: [],
      sortField: "is_active",
    },
  });

  const res = useQuery(COMPANY_USERS, {
    variables: { companyId, ...queryOptions },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      res &&
      !res.loading &&
      !res.error &&
      res.data &&
      res.data.companyUsers
    ) {
      const { users } = res.data.companyUsers;
      const userTableColumns = { ...columns };
      userTableColumns[0].columnData = [];
      userTableColumns[1].columnData = [];
      userTableColumns[2].columnData = [];
      userTableColumns[3].columnData = [];

      users.map((user) => {
        const {
          companyUserId,
          ghostUser,
          firstName,
          lastName,
          email,
          isManager,
          isActive,
        } = user;
        if (ghostUser) return null;
        const name = cleanName(firstName, lastName);
        const initials = cleanInitials(firstName, lastName);
        const cleanedEmail = cleanEmail(email);
        const role = isManager ? "Manager" : "User";
        const status = isActive ? "ACTIVE" : "INACTIVE";
        userTableColumns[0].columnData.push({
          id: `${userTableColumns[0].id}-${companyUserId}`,
          initials,
          content: name,
          companyUserId,
          queriedUser: user,
        });
        userTableColumns[1].columnData.push({
          id: `${userTableColumns[1].id}-${companyUserId}`,
          content: cleanedEmail,
          companyUserId,
          queriedUser: user,
        });
        userTableColumns[2].columnData.push({
          id: `${userTableColumns[1].id}-${companyUserId}`,
          content: role,
          companyUserId,
          queriedUser: user,
        });
        userTableColumns[3].columnData.push({
          id: `${userTableColumns[1].id}-${companyUserId}`,
          content: status,
          active: isActive,
          companyUserId,
          queriedUser: user,
        });
      });

      setColumns(userTableColumns);
      const { total } = res.data.companyUsers;
      if (total) {
        const queriedPageCount =
          total > PAGE_SIZE ? Math.ceil(total / PAGE_SIZE) : 1;
        setPageCount(queriedPageCount);
      }
      setQueryOptions({ ...queryOptions });
    } else if (
      res &&
      res.error &&
      res.error.message === "GraphQL error: unauthorized"
    ) {
      document.querySelector(".header-logout-user-action").click();
    }
  }, [res]);

  const updateActiveStatus = async () => {
    const { companyUserId, isActive } = currMutationVars.current;
    const res = await updateCompanyUser({
      variables: { companyUserId, isActive },
      fetchPolicy: "no-cache",
    });

    if (res && res.data && res.data.updateCompanyUser) {
      const userTableColumns = { ...columns };
      const { companyUserId, isActive: mutatedActiveStatus } =
        res.data.updateCompanyUser;
      userTableColumns[3].columnData.map((data) => {
        if (data.companyUserId === companyUserId) {
          data.active = mutatedActiveStatus;
          data.content = mutatedActiveStatus ? "ACTIVE" : "INACTIVE";
        }
      });

      setColumns(userTableColumns);
      setDisplay("flex");
      setSuccessMessage("The company user has been updated");
      setTimeout(() => setDisplay("none"), 3000);
    }
  };

  const activationStatusHandler = async (companyUserId, isActive) => {
    currMutationVars.current.companyUserId = companyUserId;
    currMutationVars.current.isActive = !isActive;
    if (!isActive)
      return openModal(
        updateActiveStatus,
        "Yes, Activate",
        "activate",
        "This will activate the user. An activated user can login to the application.",
      );

    return openModal(
      updateActiveStatus,
      "Yes, Deactivate",
      "deactivate",
      `This will deactivate the user. A deactivated
    user cannot login to the application. Their profile
    and associated data will be retained until they are
    reactivated again.`,
    );
  };

  const createColumnsHelper = (column) => {
    return column.columnData.map((data) => {
      if (column.isName)
        return (
          <div
            key={`table-cell-${column.id}-${data.id}`}
            className={classnames(
              "table-cell-container",
              "d-flex",
              "align-items-center",
              { "first-cell": column.id === 0 },
            )}
          >
            <div className="name-circle d-flex justify-content-center align-items-center mr-2">
              {data.initials}
            </div>
            <div style={{ marginLeft: "2.25rem" }}>{data.content}</div>
          </div>
        );

      if (column.isStatus)
        return (
          <div
            key={`column-cell-${column.id}-${data.id}`}
            className={classnames(
              "table-cell-container",
              "d-flex",
              "align-items-center",
              { "first-cell": column.id === 0 },
              "justify-content-between",
            )}
          >
            <div
              className={classnames(
                "d-flex",
                "justify-content-center",
                "align-items-center",
                { "column-status-container-active": data.active },
                { "column-status-container-inactive": !data.active },
              )}
            >
              {data.content}
            </div>
            <ActivationOptions
              companyUserId={data.companyUserId}
              activationCallback={activationStatusHandler}
              active={data.active}
              queriedUser={data.queriedUser}
              setQueriedUser={setQueriedUser}
            />
          </div>
        );

      return (
        <div
          key={`table-cell-${column.id}-${data.id}`}
          className={classnames(
            "table-cell-container",
            "d-flex",
            "align-items-center",
            { "first-cell": column.id === 0 },
          )}
        >
          {data.content}
        </div>
      );
    });
  };

  const createColumns = () => {
    return Object.keys(columns).map((key) => {
      const column = columns[key];
      const { sortField } = column;
      return (
        <div
          key={`column-${column.id}`}
          className="column-container"
          style={{ width: column.isRole || column.isStatus ? "20%" : "30%" }}
        >
          <div
            className={`column-header-container d-flex align-items-center ${
              column.id === 0 ? "first-cell" : null
            }`}
          >
            <div className="column-header-text">{column.header}</div>
            <div className="arrows-container d-flex flex-column justify-content-between ml-2">
              <img
                onClick={() =>
                  setQueryOptions({
                    ...queryOptions,
                    sortField,
                    sortDirection: "ASC",
                  })
                }
                className="arrow-img"
                src={UpArrow}
                alt="up-arrow d-flex"
              />
              <img
                onClick={() =>
                  setQueryOptions({
                    ...queryOptions,
                    sortField,
                    sortDirection: "DESC",
                  })
                }
                className="arrow-img"
                src={DownArrow}
                alt="down-arrow"
              />
            </div>
          </div>
          {createColumnsHelper(column)}
        </div>
      );
    });
  };

  const onPagination = (event) => {
    const page = event.selected + 1;
    setQueryOptions({ ...queryOptions, page });
  };

  return (
    <div className="company-users-index-container d-flex flex-column">
      <div className="company-users-table d-flex">{createColumns()}</div>
      {pageCount > 1 ? (
        <PaginatorComponent
          pageCount={pageCount}
          pageChangeHandler={onPagination}
          currentPage={1}
        />
      ) : null}
    </div>
  );
};

export default CompanyUsersIndex;
