import React from "react";
import SidebarMenuComponent from "src/shared/sidebar-menu/SidebarMenuComponent";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import PopupIcon from "src/shared/styleguide/common/PopupIcon";
import UrlUtil from "src/utils/UrlUtil";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import { QUERY_GET_CUSTOMER_CALL_CREDITS } from "src/graphql/reviews/get_reviews/queries";
import { useQuery } from "@apollo/react-hooks";
import { NETWORK_ONLY } from "src/shared/Constants";

const CallCreditInfo = () => {
  // variables
  const showCompanyId = useGetCompanyIdFromUrl();

  const buyCreditLink = UrlUtil.joinUrl(
    process.env.REACT_APP_MEMBER_URL,
    "companies",
    showCompanyId,
    "customer_call_credits",
  );

  // state
  const [callCredits, setCallCredits] = React.useState("");
  const [toBeCalled, setToBeCalled] = React.useState(0);
  const [nextGrantDate, setNextGrantDate] = React.useState("");
  const [resetDate, setResetDate] = React.useState("");

  // call apis
  useQuery(QUERY_GET_CUSTOMER_CALL_CREDITS, {
    variables: {
      companyId: showCompanyId,
    },
    fetchPolicy: NETWORK_ONLY,
    onCompleted: (data) => {
      if (data && data.getCredits) {
        setCallCredits(data.getCredits.formattedCount);
        setToBeCalled(data.getCredits.inProgress);
        setResetDate(data.getCredits.formattedDate);
        setNextGrantDate(data.getCredits.formattedGrantDate);
      }
    },
  });

  // render
  return (
    <div className="get-review-credit-info pb-2 mb-4">
      {/* search */}
      <SidebarMenuComponent
        title={
          <div>
            <div>
              Credit Info
              <span className="ml-2">
                <PopupInfo
                  placement="top"
                  message="For Customer Reviews via calls, we contact the customer by phone and transcribe the verbal customer review. A call credit is used ONLY when a customer review is obtained over the phone."
                  iconClassName="mb-1"
                />
              </span>
            </div>
            <hr className="my-1" />
          </div>
        }
        menuItems={[]}
        menuItemsFilters={{}}
        clickHandler={() => {}}
        eventName="search"
      />

      <div className="mt-n1">
        <span className="text-dark">Available Call Credits: </span>
        <span className="text-dark font-weight-bold">{callCredits}</span>
        <PopupInfo
          iconSize={14}
          iconClassName="mb-1 mx-2"
          message="If you don't have credits available, you can wait until your next credit grant or buy more now."
        />
        <a
          target="_blank"
          href={buyCreditLink}
          rel="noopener noreferrer"
          className="sg-text-info"
        >
          Buy Credits
        </a>
      </div>
      <div className="mt-2">
        <span className="text-dark">Customers to be Called: </span>
        <span className="text-dark font-weight-bold">
          {toBeCalled}
          <PopupInfo
            iconSize={14}
            iconClassName="mb-1 mx-2"
            message="Make use of your Call Credits balance by adding new names and numbers."
          />
        </span>
      </div>
      <div className="mt-2">
        <span className="text-dark">Next Grant Date: </span>
        <span className="text-dark font-weight-bold">{nextGrantDate}</span>
      </div>
      <div className="mt-3 d-flex flex-row">
        <PopupIcon
          iconSize={14}
          props={{
            className: "mb-1 mr-1",
          }}
        />
        <span className="font-italic">
          Credits for your account are granted on the {resetDate} of each month.
        </span>
      </div>
    </div>
  );
};

export default CallCreditInfo;
