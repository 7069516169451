import React, { useEffect } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  LOGIN_AS_GHOST_MUTATION,
  LOGIN_AS_PARTNER_GHOST_MUTATION,
} from "src/graphql/auth/mutation";
import { ENTERPRISE_USER_INFO } from "src/graphql/enterprise/queries";
import { GET_MY_PROFILE } from "src/graphql/users/queries";
import { setUserDetails } from "src/redux/userSlice";
import { setCookie } from "src/shared/SessionHelper";
import { COOKIES } from "src/shared/Config";
import { addToast } from "src/utils/ToastUtil";
import { setAcceptTerms } from "src/redux/userSlice";
import { QUERY_GET_COMPANY_SETTINGS } from "src/graphql/campaigns/postcard/queries";
import { REVIEW_SOURCE } from "src/shared/Constants";

const GhostUserLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userInfo } = useSelector((state) => state.user);

  const { token } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const type = queryParams.get("type");
  const companyId = queryParams.get("id");
  const loginType = queryParams.get("loginType");

  const [getCompanySettings] = useLazyQuery(QUERY_GET_COMPANY_SETTINGS, {
    variables: {
      companyId,
    },
    onCompleted: ({ companySettings }) => {
      if (companySettings?.reviewPlatform === REVIEW_SOURCE.PULSE_M) {
        setCookie(COOKIES.IS_PULSEM, true, process.env.REACT_APP_APP_DOMAIN);
      }
    },
  });

  const [getUser, { loading }] = useLazyQuery(GET_MY_PROFILE, {
    onCompleted: async ({ getCompanyUser }) => {
      await dispatch(setAcceptTerms(getCompanyUser.acceptsTerms));
      await dispatch(setUserDetails({ ...userInfo, ...getCompanyUser }));
      history.push(
        `/companies/${companyId}/${
          loginType === "fusion"
            ? "service-fusion-getting-started"
            : "dashboard"
        }`,
      );
    },
  });

  const [getEnterpriseProfile] = useLazyQuery(ENTERPRISE_USER_INFO, {
    onCompleted: async ({ getEnterpriseUser }) => {
      await dispatch(setUserDetails({ ...userInfo, ...getEnterpriseUser }));
      history.push(`/enterprise/key-metrics`);
    },
  });

  const [loginAsGhost] = useMutation(
    loginType === "fusion"
      ? LOGIN_AS_PARTNER_GHOST_MUTATION
      : LOGIN_AS_GHOST_MUTATION,
    {
      onCompleted: async (data) => {
        const ghostLogin =
          data[loginType === "fusion" ? "partnerGhostLogin" : "ghostLogin"];

        if (ghostLogin?.authenticated) {
          await dispatch(setUserDetails(ghostLogin));
          await setCookie(
            COOKIES.AUTH_TOKEN,
            ghostLogin.token,
            process.env.REACT_APP_APP_DOMAIN,
          );

          if (ghostLogin?.enterpriseUserId) {
            dispatch(setAcceptTerms(true));
            await getEnterpriseProfile({
              variables: {
                id: ghostLogin?.enterpriseUserId,
              },
            });
          } else {
            await getCompanySettings();
            await getUser({
              variables: {
                companyUserId: ghostLogin.companyUserId,
              },
            });
          }
        } else {
          addToast({
            type: "error",
            message: "Something went wrong please try again",
          });
        }
      },
    },
  );
  useEffect(() => {
    loginAsGhost({
      variables: {
        id: companyId,
        token,
        type,
      },
    });
  }, []);

  return (
    <div className="page-loader-container">
      <div class="loader"></div>
    </div>
  );
};

export default GhostUserLogin;
