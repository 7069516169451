import React from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import lockIcon from "src/assets/images/login/lock.svg";
import information from "src/assets/images/alerts/information_gray.svg";
import AuthLayout from "./AuthLayout";
import { useParams, Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-apollo";
import { addToast } from "src/utils/ToastUtil";
import { UPDATE_PASSWORD_MUTATION } from "src/graphql/auth/mutation";
import { UPDATE_ENTERPRISE_USER } from "src/graphql/enterprise/mutations";
import { UPDATE_COMPANY_USER } from "src/graphql/users/mutations";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "src/redux/userSlice";

const ResetPassword = () => {
  const { type, token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);

  const [updateEnterprisePassword] = useMutation(UPDATE_ENTERPRISE_USER, {
    onCompleted: () => {
      addToast({
        type: "success",
        message: "Enterprise user updated successfully.",
      });
      setTimeout(() => {
        dispatch(logout());
        history.push(`/${type}/login`);
      }, 1000);
    },
    onError: (err) => {
      setSubmitting(false);
      addToast({
        type: "error",
        message:
          err && err.graphQLErrors && err.graphQLErrors[0]
            ? err.graphQLErrors[0].message
            : "Please try again.",
      });
    },
  });

  const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION, {
    onCompleted: ({ updatePassword }) => {
      if (updatePassword.success) {
        addToast({
          type: "success",
          message: "Company user updated successfully.",
        });
        setTimeout(() => {
          history.push(`/${type}/login`);
        }, 1000);
      } else {
        addToast({
          type: "error",
          message:
            "We could not process your request. Try sending the email again.",
        });
        setTimeout(() => {
          history.push(`/${type}/forgot-password`);
        }, 1000);
      }
    },
    onError: (err) => {
      setSubmitting(false);
      addToast({
        type: "error",
        message:
          err && err.graphQLErrors && err.graphQLErrors[0]
            ? err.graphQLErrors[0].message
            : "Please try again.",
      });
    },
  });

  const [updateExpiredPassword] = useMutation(UPDATE_COMPANY_USER, {
    onCompleted: () => {
      addToast({
        type: "success",
        message: "Company user updated successfully.",
      });
      setTimeout(() => {
        dispatch(logout());
        history.push(`/${type}/login`);
      }, 1000);
    },
    onError: (err) => {
      setSubmitting(false);
      addToast({
        type: "error",
        message:
          err && err.graphQLErrors && err.graphQLErrors[0]
            ? err.graphQLErrors[0].message
            : "Please try again.",
      });
    },
  });

  const isExpired = history.location.pathname.includes(
    "update-expired-password",
  );

  const {
    handleSubmit,
    handleChange,
    values: { password, confirmPassword },
    touched,
    errors,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(16, "Password must be at least 16 characters long")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character",
        )
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords does not match")
        .required("Please re-enter password."),
    }),
    onSubmit: ({ confirmPassword, password }) => {
      if (isExpired) {
        if (type?.toLowerCase() === "enterprise") {
          updateEnterprisePassword({
            variables: {
              passwordConfirmation: confirmPassword,
              password,
              id: userInfo?.enterpriseUserId,
            },
          });
        } else {
          updateExpiredPassword({
            variables: {
              passwordConfirmation: confirmPassword,
              password,
              companyUserId: userInfo?.companyUserId,
            },
          });
        }
      } else {
        updatePassword({
          variables: {
            passwordConfirmation: confirmPassword,
            password,
            token,
            type,
          },
        });
      }
    },
  });

  
  return (
    <AuthLayout title={`${isExpired ? "Update" : "Reset"} Your Password`}>
      <Form className="login-form" onSubmit={handleSubmit}>
        <Row>
          <Col xs={{ span: 8, offset: 2 }}>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <img src={lockIcon} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Enter your Password"
                type="password"
                name="password"
                onChange={handleChange}
                value={password}
              />
            </InputGroup>
            {touched.password && errors.password ? (
              <div className="sg-text-danger my-2">{errors.password}</div>
            ) : null}
            <div className="password-info-box">
              <div className="password-info-text">
                <img src={information} className="mr-1" alt="Information" />
                New password must contain:
                <ul>
                  <li>At least 16 characters</li>
                  <li>At least 1 lowercase letter</li>
                  <li>At least 1 uppercase letter</li>
                  <li>At least 1 number</li>
                  <li>At least 1 special character</li>
                </ul>
              </div>
            </div>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <img src={lockIcon} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Re-type Password"
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
              />
            </InputGroup>
            {touched.confirmPassword && errors.confirmPassword ? (
              <div className="sg-text-danger my-2">
                {errors.confirmPassword}
              </div>
            ) : null}
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              className="w-100 mt-5"
            >
              {isSubmitting ? "Please wait..." : "Reset Password"}
            </Button>
            <div className="text-center mt-3">
              <p>
                Done resetting your password?{" "}
                <Link className="text-orange" to={`/${type}/login`}>
                  Login here
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Form>
    </AuthLayout>
  );
};

export default ResetPassword;
