import React, { useState, useEffect } from "react";
import "./ReviewsOverviewComponent.scss";
import rightArrow from "../../../../../../styles/assets/review-settings-icon/right-arrow-small.svg";
import ReviewsOverviewChart from "./ReviewsOverviewChart";
import { REVIEW_OVERVIEW_DATA } from "./ReviewOverviewdata";
import { query } from "../../../../../../shared/GraphQLHandler";
import { REVIEWS_SUMMARY } from "../graphql-queries/queries";
import PublishInstructionsModal from "./PublishInstructionsModal";
import { loadCookie } from "../../../../../../shared/SessionHelper";
import { COOKIES } from "../../../../../../shared/Config";
import upArrow from "../../../../../../styles/assets/review-settings-icon/up-arrow.svg";
import downArrow from "../../../../../../styles/assets/review-settings-icon/down-arrow.svg";
import NumberUtil from "src/utils/NumberUtil";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import Modal from "src/shared/styleguide/modal/Modal.jsx";

/**
 * Component to render the reviews overview component.
 * @param filters - date range filters
 * @returns {XML} -> Return the ReviewsOverviewComponent
 */
const ReviewsOverviewComponent = ({ props, filters }) => {
  const [filter, updateFilter] = useState(filters);
  const companyId = useGetCompanyIdFromUrl();

  const [publishInstructionModal, setPublishInstructionModal] = useState(false);

  const positiveReviewChartColor = {
    linearGradient: { x1: 1, x2: 1, y1: 0, y2: 1 },
    stops: [
      [0, "rgba(244, 120, 33, 0.25)"],
      [1, "rgba(244, 120, 33, 0.08)"],
    ],
  };

  const negativeReviewChartColor = {
    linearGradient: { x1: 1, x2: 1, y1: 0, y2: 1 },
    stops: [
      [0, "rgba(244, 33, 52, 0.36)"],
      [1, "rgba(244, 33, 52, 0.08)"],
    ],
  };

  const noArrowClass = (counts) => {
    return (
      counts.currentPeriod === counts.previousPeriod &&
      counts.currentPeriod === 0
    );
  };

  const trendClass = (counts) => {
    if (counts.currentPeriod > counts.previousPeriod) {
      return "up-percentage";
    } else if (counts.currentPeriod < counts.previousPeriod) {
      return "down-percentage";
    }
    return "no-change";
  };

  /**
   * Detect daterange filter change
   */
  useEffect(() => {
    // updateFilter(filters);
    reviewsOverview();
  }, [filters]);

  /**
   * method to get review overview data
   */
  const getReviewOverviewData = () => {
    const filteredData = REVIEW_OVERVIEW_DATA.filter((data) => {
      return data.days === filter.dateRange.value;
    });
    return filteredData[0];
  };

  const updatePublishInstructionModal = () => {
    setPublishInstructionModal(!publishInstructionModal);
  };

  const percent = (currCount, oldCount) => {
    if (oldCount === 0 && currCount === 0) {
      return (0.0).toFixed(1);
    } else if (oldCount === 0) {
      return (100.0).toFixed(1);
    } else {
      var val = ((currCount - oldCount) * 100) / (currCount + oldCount);
      return val.toFixed(1);
    }
  };

  const reviewsOverview = () => {
    let variables = {
      companyId: parseInt(companyId, 10),
      dateFrom: filters.dateRange.value,
      dateBucket: filters.dateRange.bucket,
    };
    query(
      props.client,
      REVIEWS_SUMMARY,
      function (error, response) {
        if (response && response.data && response.data.reviewSummary) {
          setreviewOverviewData(response.data.reviewSummary);
        } else {
          console.log("Error in getting filters list: ", error);
        }
      },
      variables,
    );
  };

  let [reviewOverviewData, setreviewOverviewData] = useState(
    getReviewOverviewData(),
  );

  // let reviewOverviewData = getReviewOverviewData();
  let { positiveCounts, negativeCounts, pendingCounts } =
    reviewOverviewData || {
      positiveCounts: {
        currentPeriod: 0,
        previousPeriod: 0,
      },
      negativeCounts: {
        currentPeriod: 0,
        previousPeriod: 0,
      },
      pendingCounts: {
        reviewPercentage: 0,
        reviewCount: 0,
      },
    };
  return (
    <div className="row mt-3" id="reviews-overview">
      <div className="col-12">
        {publishInstructionModal ? (
          <Modal
            className="modal-view"
            onHide={() => updatePublishInstructionModal(false)}
            header="Guidelines for Publishing Reviews"
            body={<PublishInstructionsModal />}
            show={publishInstructionModal}
          />
        ) : null}
        <div className="row">
          <div className="col-12">
            <div className="reviews-heading mb-3">Reviews Overview</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4 pr-lg-1 mt-3 mt-sm-2">
            <div className="reviews-border">
              <p className="reviews-subheading text-uppercase">
                Positive Reviews
              </p>
              <div className="row mt-2">
                <div className="col-12 overiew-stats">
                  <div className="reviews-count">
                    {" "}
                    {NumberUtil.toK(
                      positiveCounts.currentPeriod
                        ? parseInt(positiveCounts.currentPeriod, 10)
                        : positiveCounts.currentPeriod,
                      1,
                    )}{" "}
                  </div>
                  <div className="px-2">
                    {noArrowClass(positiveCounts) ? null : (
                      <img
                        src={
                          positiveCounts.currentPeriod <
                          positiveCounts.previousPeriod
                            ? downArrow
                            : upArrow
                        }
                        className="arrow"
                        alt="Arrow"
                      />
                    )}
                  </div>
                  <div
                    className={
                      "reviews-percentage " + trendClass(positiveCounts)
                    }
                  >
                    {" "}
                    {`${percent(
                      positiveCounts.currentPeriod,
                      positiveCounts.previousPeriod,
                    )}%`}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 px-0">
                  <ReviewsOverviewChart
                    chartId="positive-reviews"
                    chartData={positiveCounts.trends}
                    dateRanges={positiveCounts.dateRanges}
                    color={positiveReviewChartColor}
                    seriesTitle="Positive Reviews"
                    seriesStroke="positive-review-stroke"
                    markerColor="#f47821"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 pr-lg-1 mt-3 mt-sm-2">
            <div className="reviews-border">
              <p className="reviews-subheading text-uppercase">
                Negative Reviews
              </p>
              <div className="row mt-2">
                <div className="col-12 overiew-stats">
                  <div className="reviews-count">
                    {" "}
                    {NumberUtil.toK(
                      negativeCounts.currentPeriod
                        ? parseInt(negativeCounts.currentPeriod, 10)
                        : negativeCounts.currentPeriod,
                      1,
                    )}{" "}
                  </div>
                  <div className="px-2">
                    {noArrowClass(negativeCounts) ? null : (
                      <img
                        src={
                          negativeCounts.currentPeriod >=
                          negativeCounts.previousPeriod
                            ? upArrow
                            : downArrow
                        }
                        className="arrow"
                        alt="Arrow"
                      />
                    )}
                  </div>
                  <div
                    className={
                      "reviews-percentage " + trendClass(negativeCounts)
                    }
                  >
                    {" "}
                    {`${percent(
                      negativeCounts.currentPeriod,
                      negativeCounts.previousPeriod,
                    )}%`}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 px-0">
                  <ReviewsOverviewChart
                    chartId="negative-reviews"
                    chartData={negativeCounts.trends}
                    dateRanges={negativeCounts.dateRanges}
                    color={negativeReviewChartColor}
                    seriesTitle="Negative Reviews"
                    seriesStroke="negative-review-stroke"
                    markerColor="#f42134"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 pr-lg-1 mt-3 mt-sm-2">
            <div className="reviews-border">
              <p className="reviews-subheading">
                Why do I have Pending reviews?
              </p>
              <div className="row mt-2">
                <div className="col-12">
                  <p className="pending-review-sub-text">
                    {" "}
                    {pendingCounts.reviewPercentage}% of your reviews have a
                    ‘pending’ status. (
                    {NumberUtil.toK(
                      pendingCounts.reviewsCount
                        ? parseInt(pendingCounts.reviewsCount, 10)
                        : pendingCounts.reviewsCount,
                      1,
                    )}{" "}
                    reviews)
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <a
                    href=""
                    className="reviews-learn-more"
                    onClick={() => updatePublishInstructionModal()}
                  >
                    Learn more
                    <img
                      src={rightArrow}
                      className="menu-icon mx-xl-1 mx-2 mr-2"
                      alt="right-arrow"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsOverviewComponent;
